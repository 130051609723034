import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../styles/BuySellDrawer.module.scss";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { colourStylesCurrency } from "./SelectCurrencyStyles";
import { colourStylesLeverage } from "./SelectLeverageStyles";
import altIcon from "../imgs/altIcon.svg";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

export default function BuySellDrawer({
  open,
  toggleDrawer,
  theme,
  active,
  activePrice,
  eurToUsd,
  usdToEur,
  buyOrder,
  sellOrder,
  backgroundOrderStyle,
  handleTabOrderChange,
  selectedOrderTab,
  account,
  setSnackType,
  setSnackMessage,
  handleClick,
}) {
  const [accountType, setAccountType] = useState("Real");
  const { t } = useTranslation();
  const [amount, setAmount] = useState(1);
  const [leverage, setLeverage] = useState(1);
  const [orderType, setOrderType] = useState("");
  const [takeProfit, setTakeProfit] = useState('');
  const [openWhen, setOpenWhen] = useState("");
  const [stopLoss, setStopLoss] = useState('');
  const [expectedProfit, setExpectedProfit] = useState("");
  const [chosenBottomButton, setChosenBottomButton] = useState("Market");
  const [buttonTakeProfit, setButtonTakeProfit] = useState(false);
  const [buttonStopLoss, setButtonStopLoss] = useState(false);
  const [selectedOption, setSelectedOption] = useState("gtc");
  const [EntryPrice, setPrice] = useState(0 );
  const [ticks, setTicks] = useState(0);
  const [units, setUnits] = useState(1);
  const [takeProfitError , setTakeProfitError] = useState(false);
  const [stopLossError , setStopLossError] = useState(false);
  const [stopError, setStopError] = useState(false);
  const [limitError, setLimitError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [valuesProfit, setValuesProfit] = useState({
    ticks: null,
    price: null,
    money: null,
    percents: null,
  });
  const [valuesLoss, setValuesLoss] = useState({
    ticks: null,
    price: null,
    money: null,
    percents: null,
  });

    useEffect(()=>{
        setPrice((active?.Price?active?.Price * (account?.AccountCurrency === 'EUR'?eurToUsd:1):0).toFixed(4))
    },[active?.Price])

    useEffect(()=>{
        setTakeProfit(valuesProfit.price)
    },[valuesProfit.price])
    
    useEffect(()=>{
        setStopLoss(valuesLoss.price)
    },[valuesLoss.price])
 
    useEffect(()=>{
        if(stopLoss){
            handleChangeValuesLoss('ticks' ,  valuesLoss?.ticks)
        }
        if(takeProfit){
            handleChangeValuesProfit('ticks' ,  valuesProfit?.ticks)
        }
    },[selectedOrderTab , amount , EntryPrice])

    const handleChangeValuesProfit = (name, value) => {
      const parsedValue = value === "" ? "" : parseFloat(value) || 0;
    setTakeProfitError(false)
    let updatedInputs = {
      };
      if(chosenBottomButton === 'Market'){
        switch (name) {
            case "ticks":
                if(selectedOrderTab === 'buy'){
                    updatedInputs = {
                      ticks: parsedValue,
                      price: ((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)) + (parsedValue * (1/ active?.LotSize))).toFixed(2),
                      money: (amount * (parsedValue * (1 / active?.LotSize))).toFixed(2),
                      percents: Math.abs((((parsedValue * (1 / active?.LotSize)))/((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) * 100).toFixed(2),
                    };
                }else{
                    updatedInputs = {
                      ticks: parsedValue,
                      price: ((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)) - (parsedValue * (1/ active?.LotSize))).toFixed(2),
                      money: (amount * (parsedValue * (1 / active?.LotSize))).toFixed(2),
                      percents: Math.abs((((parsedValue * (1 / active?.LotSize)))/((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) * 100).toFixed(2),
                    };
                }
              break;
            case "price":
                if(selectedOrderTab === 'buy'){
                    updatedInputs = {
                      ticks: Math.abs((parsedValue - (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1))) / (1 / active?.LotSize)).toFixed(2),
                      price: parsedValue,
                      money: (amount * (parsedValue - (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))).toFixed(2),
                      percents: Math.abs(((parsedValue - (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) / ((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1))) * 100 ).toFixed(2),
                    };
                }else{
                    updatedInputs = {
                      ticks: Math.abs((parsedValue - (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1))) / (1 / active?.LotSize)* -1).toFixed(2),
                      price: parsedValue,
                      money: (amount * (parsedValue - (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))* -1).toFixed(2),
                      percents: Math.abs(((parsedValue - (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) / ((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1))) * 100 * -1).toFixed(2),
                    };
                }
              break;
            case "money":
                if(selectedOrderTab === 'buy'){
                    updatedInputs = {
                    ticks: Math.abs((parsedValue / amount) / (1/active?.LotSize)).toFixed(2),
                    price: (parsedValue / amount + (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1))).toFixed(2),
                    money: parsedValue,
                    percents: Math.abs((parsedValue / (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) * 100).toFixed(2),
                    };
                }else{
                    updatedInputs = {
                        ticks: Math.abs((parsedValue / amount) / (1/active?.LotSize)).toFixed(2),
                        price: ((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)) - parsedValue / amount).toFixed(2),
                        money: parsedValue,
                        percents: Math.abs((parsedValue / (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) * 100).toFixed(2),
                    };
                }
              break;
            case "percents":
                if(selectedOrderTab === 'buy'){
                    updatedInputs = {
                      ticks: Math.abs(((parsedValue / 100) * (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) / amount / (1/active?.LotSize)).toFixed(2),
                      price: (((parsedValue / 100) * (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) / amount + (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1))).toFixed(2),
                      money: ((parsedValue / 100) * (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))).toFixed(2),
                      percents: parsedValue,
                    };
                }else{
                    updatedInputs = {
                      ticks: Math.abs(((parsedValue / 100) * (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) / amount / (1/active?.LotSize)).toFixed(2),
                      price: ( (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)) - ((parsedValue / 100) * (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) / amount).toFixed(2),
                      money: ((parsedValue / 100) * (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))).toFixed(2),
                      percents: parsedValue,
                    };
                }
              break;
            default:
              break;
          }
      }else{
        switch (name) {
          case "ticks":
              if(selectedOrderTab === 'buy'){
                  updatedInputs = {
                    ticks: parsedValue,
                    price: parseFloat(parseFloat(EntryPrice) + (parsedValue * (1/ active?.LotSize))).toFixed(2),
                    money: (amount * (parsedValue * (1 / active?.LotSize))).toFixed(2),
                    percents: Math.abs((((parsedValue * (1 / active?.LotSize)))/(parseFloat(EntryPrice))) * 100).toFixed(2),
                  };
              }else{
                  updatedInputs = {
                    ticks: parsedValue,
                    price: parseFloat(parseFloat(EntryPrice) - (parsedValue * (1/ active?.LotSize))).toFixed(2),
                    money: (amount * (parsedValue * (1 / active?.LotSize))).toFixed(2),
                    percents: Math.abs((((parsedValue * (1 / active?.LotSize)))/(parseFloat(EntryPrice))) * 100).toFixed(2),
                  };
              }
            break;
          case "price":
              if(selectedOrderTab === 'buy'){
                  updatedInputs = {
                    ticks: Math.abs((parsedValue - parseFloat(EntryPrice)) / (1 / active?.LotSize)).toFixed(2),
                    price: parsedValue,
                    money: (amount * (parsedValue - parseFloat(EntryPrice))).toFixed(2),
                    percents: Math.abs(((parsedValue - parseFloat(EntryPrice))) / parseFloat(EntryPrice) * 100 ).toFixed(2),
                  };
              }else{
                  updatedInputs = {
                    ticks: Math.abs((parsedValue - parseFloat(EntryPrice)) / (1 / active?.LotSize)* -1).toFixed(2),
                    price: parsedValue,
                    money: (amount * (parsedValue - parseFloat(EntryPrice))* -1).toFixed(2),
                    percents: Math.abs(((parsedValue - parseFloat(EntryPrice))) / (parseFloat(EntryPrice)) * 100 * -1).toFixed(2),
                  };
              }
            break;
          case "money":
              if(selectedOrderTab === 'buy'){
                  updatedInputs = {
                  ticks: Math.abs((parsedValue / amount) / (1/active?.LotSize)).toFixed(2),
                  price: parseFloat(parsedValue / amount + parseFloat(EntryPrice)).toFixed(2),
                  money: parsedValue,
                  percents: Math.abs((parsedValue / (amount * parseFloat(EntryPrice))) * 100).toFixed(2),
                  };
              }else{
                  updatedInputs = {
                      ticks: Math.abs((parsedValue / amount) / (1/active?.LotSize)).toFixed(2),
                      price: parseFloat(parseFloat(EntryPrice) - parsedValue / amount).toFixed(2),
                      money: parsedValue,
                      percents: Math.abs((parsedValue / (amount * parseFloat(EntryPrice))) * 100).toFixed(2),
                  };
              }
            break;
          case "percents":
              if(selectedOrderTab === 'buy'){
                  updatedInputs = {
                    ticks: Math.abs(((parsedValue / 100) * (amount * parseFloat(EntryPrice))) / amount / (1/active?.LotSize)).toFixed(2),
                    price: parseFloat(((parsedValue / 100) * (amount * parseFloat(EntryPrice))) / amount + parseFloat(EntryPrice)).toFixed(2),
                    money: ((parsedValue / 100) * (amount * parseFloat(EntryPrice))).toFixed(2),
                    percents: parsedValue,
                  };
              }else{
                  updatedInputs = {
                    ticks: Math.abs(((parsedValue / 100) * (amount * parseFloat(EntryPrice))) / amount / (1/active?.LotSize)).toFixed(2),
                    price: parseFloat(parseFloat(EntryPrice) - ((parsedValue / 100) * (amount * parseFloat(EntryPrice))) / amount).toFixed(2),
                    money: ((parsedValue / 100) * (amount * parseFloat(EntryPrice))).toFixed(2),
                    percents: parsedValue,
                  };
              }
            break;
          default:
            break;
        }
      }
    setValuesProfit((prev) => ({
    ...prev,
    ...updatedInputs,
    }));
    

  };

  const handleChangeValuesLoss = (name, value) => {    
    const parsedValue = value === "" ? "" : parseFloat(value) || 0;
    setStopLossError(false)
    let updatedInputs = {
      };
      if(chosenBottomButton === 'Market'){
        switch (name) {
          case "ticks":
              if(selectedOrderTab === 'buy'){
                  updatedInputs = {
                    ticks: parsedValue,
                    price: ((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)) - (parsedValue * (1/ active?.LotSize))).toFixed(2),
                    money: (amount * (parsedValue * (1 / active?.LotSize)) * -1).toFixed(2),
                    percents: Math.abs((((parsedValue * (1 / active?.LotSize)))/((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) * 100).toFixed(2),
                  };
              }else{
                  updatedInputs = {
                    ticks: parsedValue,
                    price: ((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)) + (parsedValue * (1/ active?.LotSize))).toFixed(2),
                    money: (amount * (parsedValue * (1 / active?.LotSize)) * -1).toFixed(2),
                    percents: Math.abs((((parsedValue * (1 / active?.LotSize)))/((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) * 100).toFixed(2),
                  };
              }
            break;
          case "price":
              if(selectedOrderTab === 'buy'){
                  updatedInputs = {
                    ticks: Math.abs((parsedValue - (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1))) / (1 / active?.LotSize)).toFixed(2),
                    price: parsedValue,
                    money: (amount * (parsedValue - (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))).toFixed(2),
                    percents: Math.abs(((parsedValue - (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) / ((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1))) * 100 ).toFixed(2),
                  };
              }else{
                  updatedInputs = {
                    ticks: Math.abs((parsedValue - (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1))) / (1 / active?.LotSize)).toFixed(2),
                    price: parsedValue,
                    money: (amount * (parsedValue - (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))* -1).toFixed(2),
                    percents: Math.abs(((parsedValue - (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) / ((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1))) * 100).toFixed(2),
                  };
              }
            break;
          case "money":
              if(selectedOrderTab === 'buy'){
                  updatedInputs = {
                  ticks: Math.abs((parsedValue / amount) / (1/active?.LotSize)).toFixed(2),
                  price: (parsedValue / amount + (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1))).toFixed(2),
                  money: parsedValue,
                  percents: Math.abs((parsedValue / (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) * 100).toFixed(2),
                  };
              }else{
                  updatedInputs = {
                      ticks: Math.abs((parsedValue / amount) / (1/active?.LotSize)).toFixed(2),
                      price: ((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)) - parsedValue / amount).toFixed(2),
                      money: parsedValue,
                      percents: Math.abs((parsedValue / (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) * 100).toFixed(2),
                  };
              }
            break;
          case "percents":
              if(selectedOrderTab === 'buy'){
                  updatedInputs = {
                    ticks: Math.abs(((parsedValue / 100) * (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) / amount / (1/active?.LotSize)).toFixed(2),
                    price: ((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)) - ((parsedValue / 100) * (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) / amount ).toFixed(2),
                    money: ((parsedValue / 100) * (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1))) * -1).toFixed(2),
                    percents: parsedValue,
                  };
              }else{
                  updatedInputs = {
                    ticks: Math.abs(((parsedValue / 100) * (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) / amount / (1/active?.LotSize)).toFixed(2),
                    price: ( (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)) + ((parsedValue / 100) * (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))) / amount).toFixed(2),
                    money: ((parsedValue / 100) * (amount * (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)))).toFixed(2),
                    percents: parsedValue,
                  };
              }
            break;
          default:
            break;
        }

      }else{
        switch (name) {
            case "ticks":
                if(selectedOrderTab === 'buy'){
                    updatedInputs = {
                    ticks: parsedValue,
                    price: parseFloat(parseFloat(EntryPrice) - (parsedValue * (1/ active?.LotSize))).toFixed(2),
                    money: (amount * (parsedValue * (1 / active?.LotSize)) * -1).toFixed(2),
                    percents: Math.abs((((parsedValue * (1 / active?.LotSize)))/(parseFloat(EntryPrice))) * 100).toFixed(2),
                    };
                }else{
                    updatedInputs = {
                    ticks: parsedValue,
                    price: parseFloat(parseFloat(EntryPrice) + (parsedValue * (1/ active?.LotSize))).toFixed(2),
                    money: (amount * (parsedValue * (1 / active?.LotSize)) * -1).toFixed(2),
                    percents: Math.abs((((parsedValue * (1 / active?.LotSize)))/(parseFloat(EntryPrice))) * 100).toFixed(2),
                    };
                }
            break;
            case "price":
                if(selectedOrderTab === 'buy'){
                    updatedInputs = {
                    ticks: Math.abs((parsedValue - parseFloat(EntryPrice)) / (1 / active?.LotSize)).toFixed(2),
                    price: parsedValue,
                    money: (amount * (parsedValue - parseFloat(EntryPrice))).toFixed(2),
                    percents: Math.abs(((parsedValue - parseFloat(EntryPrice))) / (parseFloat(EntryPrice)) * 100 ).toFixed(2),
                    };
                }else{
                    updatedInputs = {
                    ticks: Math.abs((parsedValue - parseFloat(EntryPrice)) / (1 / active?.LotSize)).toFixed(2),
                    price: parsedValue,
                    money: (amount * (parsedValue - parseFloat(EntryPrice))* -1).toFixed(2),
                    percents: Math.abs(((parsedValue - parseFloat(EntryPrice))) / (parseFloat(EntryPrice)) * 100).toFixed(2),
                    };
                }
            break;
            case "money":
                if(selectedOrderTab === 'buy'){
                    updatedInputs = {
                    ticks: Math.abs((parsedValue / amount) / (1/active?.LotSize)).toFixed(2),
                    price: parseFloat(parsedValue / amount + parseFloat(EntryPrice)).toFixed(2),
                    money: parsedValue,
                    percents: Math.abs((parsedValue / (amount * parseFloat(EntryPrice))) * 100).toFixed(2),
                    };
                }else{
                    updatedInputs = {
                        ticks: Math.abs((parsedValue / amount) / (1/active?.LotSize)).toFixed(2),
                        price: parseFloat(parseFloat(EntryPrice) - parsedValue / amount).toFixed(2),
                        money: parsedValue,
                        percents: Math.abs((parsedValue / (amount * parseFloat(EntryPrice))) * 100).toFixed(2),
                    };
                }
            break;
            case "percents":
                if(selectedOrderTab === 'buy'){
                    updatedInputs = {
                    ticks: Math.abs(((parsedValue / 100) * (amount * parseFloat(EntryPrice))) / amount / (1/active?.LotSize)).toFixed(2),
                    price: parseFloat(parseFloat(EntryPrice) - ((parsedValue / 100) * (amount * parseFloat(EntryPrice))) / amount ).toFixed(2),
                    money: ((parsedValue / 100) * (amount * parseFloat(EntryPrice)) * -1).toFixed(2),
                    percents: parsedValue,
                    };
                }else{
                    updatedInputs = {
                    ticks: Math.abs(((parsedValue / 100) * (amount * parseFloat(EntryPrice))) / amount / (1/active?.LotSize)).toFixed(2),
                    price: parseFloat(parseFloat(EntryPrice) + ((parsedValue / 100) * (amount * parseFloat(EntryPrice))) / amount).toFixed(2),
                    money: ((parsedValue / 100) * (amount * parseFloat(EntryPrice))).toFixed(2),
                    percents: parsedValue,
                    };
                }
            break;
            default:
            break;
        }
      }
    setValuesLoss((prev) => ({
        ...prev,
        ...updatedInputs,
        }));
  };

  useEffect(()=>{
    if(chosenBottomButton !== "Market" && ticks)
    handleChangeLimitStop('ticks', ticks)
  },[selectedOrderTab, chosenBottomButton])


  const handleChangeLimitStop = (name, value) => {    
    const parsedValue = value === "" ? "" : parseFloat(value) || 0;
    setLimitError(false)
    setStopError(false)
      switch (name) {
        case "ticks":
            setTicks(parsedValue);
            if((selectedOrderTab === 'buy' && chosenBottomButton === 'Limit') || (selectedOrderTab === 'sell' && chosenBottomButton === 'Stop')){
                setPrice(parseFloat((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)) - parsedValue * (1/active?.LotSize)).toFixed(4))
            }else if((selectedOrderTab === 'sell' && chosenBottomButton === 'Limit')|| (selectedOrderTab === 'buy' && chosenBottomButton === 'Stop')){
                setPrice(parseFloat((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)) + parsedValue * (1/active?.LotSize)).toFixed(4))
            }
          break;
        case "price":
            setPrice(parsedValue);
            if(selectedOrderTab === 'buy'){
                setTicks(Math.abs(((activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)) - parsedValue) / (1/ active?.LotSize)).toFixed(4))
            }else{
                setTicks(Math.abs((parsedValue - (activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1))) / (1/ active?.LotSize)).toFixed(4))
            }
          break;
        default:
          break;
      }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCheckboxChange1 = (event) => {
    setButtonTakeProfit(event.target.checked);
  };

  const handleCheckboxChange2 = (event) => {
    setButtonStopLoss(event.target.checked);
  };

  const handleExpectedProfitChange = (e) => {
    const profit = parseFloat(e.target.value);
    setExpectedProfit(profit);
  };

  const handleImgError = (e) => {
    e.target.src = altIcon; 
  };

  const OpenPosition = async () => {
    if (!selectedOrderTab) {
      handleClick();
      setSnackMessage(t("OrderTypeError"));
      setSnackType("error");
      return;
    }
    let errors = 0;
    if (buttonTakeProfit) {
        if(chosenBottomButton === 'Market'){
            if (
              takeProfit &&
              selectedOrderTab === "buy" &&
              +takeProfit <=
                parseFloat(
                  account?.AccountCurrency == "EUR"
                    ? activePrice * eurToUsd
                    : activePrice
                )
            ) {
              handleClick();
              setSnackMessage(t("MustBeHigher"));
              setSnackType("error");
              setTakeProfitError(true)
              ++errors
            }
            if (
              takeProfit &&
              selectedOrderTab === "sell" &&
              +takeProfit >=
                parseFloat(
                  account?.AccountCurrency == "EUR"
                    ? activePrice * eurToUsd
                    : activePrice
                )
            ) {
              handleClick();
              setSnackMessage(t("MustBeLower"));
              setSnackType("error");
              ++errors
              setTakeProfitError(true)
            }
        }
        if(chosenBottomButton === 'Limit'){
            if (
              takeProfit &&
              selectedOrderTab === "buy" &&
              +takeProfit <=
                parseFloat(
                  account?.AccountCurrency == "EUR"
                    ? EntryPrice * eurToUsd
                    : EntryPrice
                )
            ) {
              handleClick();
              setSnackMessage(t("MustBeHigher"));
              setSnackType("error");
              setTakeProfitError(true)
              ++errors
            }
            if (
              takeProfit &&
              selectedOrderTab === "sell" &&
              +takeProfit >=
                parseFloat(
                  account?.AccountCurrency == "EUR"
                    ? EntryPrice * eurToUsd
                    : EntryPrice
                )
            ) {
              handleClick();
              setSnackMessage(t("MustBeLower"));
              setSnackType("error");
              ++errors
              setTakeProfitError(true)
            }

        }
        if(chosenBottomButton === 'Stop'){
            if (
              takeProfit &&
              selectedOrderTab === "buy" &&
              +takeProfit <=
                parseFloat(
                  account?.AccountCurrency == "EUR"
                    ? EntryPrice * eurToUsd
                    : EntryPrice
                )
            ) {
              handleClick();
              setSnackMessage(t("MustBeHigher"));
              setSnackType("error");
              setTakeProfitError(true)
              ++errors
            }
            if (
              takeProfit &&
              selectedOrderTab === "sell" &&
              +takeProfit >=
                parseFloat(
                  account?.AccountCurrency == "EUR"
                    ? EntryPrice * eurToUsd
                    : EntryPrice
                )
            ) {
              handleClick();
              setSnackMessage(t("MustBeLower"));
              setSnackType("error");
              ++errors
              setTakeProfitError(true)
            }

        }
    }
    if (buttonStopLoss) {
        if(chosenBottomButton === 'Market'){
            if (
              stopLoss &&
              selectedOrderTab === "buy" &&
              +stopLoss >=
                parseFloat(
                  account?.AccountCurrency == "EUR"
                    ? activePrice * eurToUsd
                    : activePrice
                )
            ) {
              handleClick();
              setSnackMessage(t("MustBeLower"));
              setSnackType("error");
              ++errors
              setStopLossError(true)
            }
            if (
              stopLoss &&
              selectedOrderTab === "sell" &&
              +stopLoss <=
                parseFloat(
                  account?.AccountCurrency == "EUR"
                    ? activePrice * eurToUsd
                    : activePrice
                )
            ) {
              console.log(takeProfit);
              handleClick();
              setSnackMessage(t("MustBeHigher"));
              setSnackType("error");
              ++errors
              setStopLossError(true)
            }
        }
        if(chosenBottomButton === 'Limit'){
            if (
              stopLoss &&
              selectedOrderTab === "buy" &&
              +stopLoss >=
                parseFloat(
                  account?.AccountCurrency == "EUR"
                    ? EntryPrice * eurToUsd
                    : EntryPrice
                )
            ) {
              handleClick();
              setSnackMessage(t("MustBeLower"));
              setSnackType("error");
              ++errors
              setStopLossError(true)
            }
            if (
              stopLoss &&
              selectedOrderTab === "sell" &&
              +stopLoss <=
                parseFloat(
                  account?.AccountCurrency == "EUR"
                    ? EntryPrice * eurToUsd
                    : EntryPrice
                )
            ) {
              console.log(takeProfit);
              handleClick();
              setSnackMessage(t("MustBeHigher"));
              setSnackType("error");
              ++errors
              setStopLossError(true)
            }
        }
        if(chosenBottomButton === 'Stop'){
            if (
              stopLoss &&
              selectedOrderTab === "buy" &&
              +stopLoss >=
                parseFloat(
                  account?.AccountCurrency == "EUR"
                    ? EntryPrice * eurToUsd
                    : EntryPrice
                )
            ) {
              handleClick();
              setSnackMessage(t("MustBeLower"));
              setSnackType("error");
              ++errors
              setStopLossError(true)
            }
            if (
              stopLoss &&
              selectedOrderTab === "sell" &&
              +stopLoss <=
                parseFloat(
                  account?.AccountCurrency == "EUR"
                    ? EntryPrice * eurToUsd
                    : EntryPrice
                )
            ) {
              console.log(takeProfit);
              handleClick();
              setSnackMessage(t("MustBeHigher"));
              setSnackType("error");
              ++errors
              setStopLossError(true)
            }
        }
    }
    if(errors !== 0){
        return
    }
    if(chosenBottomButton === 'Limit' && selectedOrderTab === 'buy' && EntryPrice >= (activePrice * (account.AccountCurrency === 'EUR'? eurToUsd:1 )) ){
        handleClick();
        setSnackMessage(t("MustBeLower"));
        setSnackType("error");
        setLimitError(true)
        return
    }
    if(chosenBottomButton === 'Limit' && selectedOrderTab === 'sell' && EntryPrice <= (activePrice * (account.AccountCurrency === 'EUR'? eurToUsd:1 )) ){
        handleClick();
        setSnackMessage(t("MustBeHigher"));
        setSnackType("error");
        setLimitError(true)
        return
    }
    if(chosenBottomButton === 'Stop' && selectedOrderTab === 'buy' && EntryPrice <= (activePrice * (account.AccountCurrency === 'EUR'? eurToUsd:1 )) ){
        
        console.log(EntryPrice)
        console.log(activePrice)
        handleClick();
        setSnackMessage(t("MustBeHigher"));
        setSnackType("error");
        setStopError(true)
        return
    }
    if(chosenBottomButton === 'Stop' && selectedOrderTab === 'sell' && EntryPrice >= (activePrice * (account.AccountCurrency === 'EUR'? eurToUsd:1 )) ){
        console.log(2)

        handleClick();
        setSnackMessage(t("MustBeLower"));
        setSnackType("error");
        setStopError(true)
        return
    }
    if(!amount || amount === 0){
      handleClick();
      setSnackMessage(t("AmountError"));
      setSnackType("error");
      setAmountError(true);
      return;
    }
    if (selectedOrderTab === "buy") {
      Buy();
      toggleDrawer(false,selectedOrderTab)();
    }
    if (selectedOrderTab === "sell") {
      Sell();
      toggleDrawer(false,selectedOrderTab)();
    }
  };

  const Buy = async () => {
    if (!/^(?!0\.?0*$)\d+(\.\d+)?$/.test(amount)) {
      handleClick();
      setSnackMessage(t("BuySellError"));
      setSnackType("error");
      toggleDrawer(false,selectedOrderTab)();
      return;
    }
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_test_url +
          `/api/account/assets/${active.Id}/buy/long`,
        {
          user_id: account?.Id,
          sum: chosenBottomButton !== 'Market'
            ? (
                parseFloat(
                  amount *
                    active?.LotSize *
                    (account?.AccountCurrency == "EUR"
                      ? EntryPrice * usdToEur
                      : EntryPrice)
                ) / leverage
              ).toFixed(2)
            : (
                parseFloat(
                  amount *
                    active?.LotSize *
                    (account?.AccountCurrency == "EUR"
                      ? activePrice * usdToEur
                      : activePrice)
                ) / leverage
              ).toFixed(2),
          count: amount,
          tp: buttonTakeProfit
            ? takeProfit
              ? account?.AccountCurrency == "EUR"
                ? takeProfit * usdToEur
                : takeProfit
              : 0
            : 0,
          sl: buttonStopLoss
            ? stopLoss
              ? account?.AccountCurrency == "EUR"
                ? stopLoss * usdToEur
                : stopLoss
              : 0
            : 0,
          leverage: leverage,
          time_in_force: selectedOption,
          openWhen: chosenBottomButton !== 'Market'
            ? account?.AccountCurrency == "EUR"
              ? EntryPrice * usdToEur
              : EntryPrice
            : 0,
          order_type: chosenBottomButton,
        },
        {
          headers: {
            Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
          },
        }
      );
      if (data) {
        handleClick();
        setSnackMessage(t("BuySuccess"));
        setSnackType("success");
      }
    } catch (e) {
      console.log(e);
      handleClick();
      setSnackMessage(e.response.data.message);
      setSnackType("error");
      toggleDrawer(false,selectedOrderTab)();
    }
  };

  const Sell = async () => {
    if (!/^(?!0\.?0*$)\d+(\.\d+)?$/.test(amount)) {
      handleClick();
      setSnackMessage(t("BuySellError"));
      setSnackType("error");
      toggleDrawer(false,selectedOrderTab)();
      return;
    }
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_test_url +
          `/api/account/assets/${active.Id}/sell/short`,
        {
          user_id: account?.Id,
          sum: EntryPrice
            ? (
                parseFloat(
                  amount *
                    active?.LotSize *
                    (account?.AccountCurrency == "EUR"
                      ? EntryPrice * usdToEur
                      : EntryPrice)
                ) / leverage
              ).toFixed(2)
            : (
                parseFloat(
                  amount *
                    active?.LotSize *
                    (account?.AccountCurrency == "EUR"
                      ? activePrice * usdToEur
                      : activePrice)
                ) / leverage
              ).toFixed(2),
          count: amount,
          tp: buttonTakeProfit
            ? takeProfit
              ? account?.AccountCurrency == "EUR"
                ? takeProfit * usdToEur
                : takeProfit
              : 0
            : 0,
          sl: buttonStopLoss
            ? stopLoss
              ? account?.AccountCurrency == "EUR"
                ? stopLoss * usdToEur
                : stopLoss
              : 0
            : 0,
          leverage: leverage,
          time_in_force: selectedOption,
          openWhen: EntryPrice
            ? account?.AccountCurrency == "EUR"
              ? EntryPrice * usdToEur
              : EntryPrice
            : 0,
          order_type: chosenBottomButton,
        },
        {
          headers: {
            Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
          },
        }
      );
      if (data) {
        handleClick();
        setSnackMessage(t("SellSuccess"));
        setSnackType("success");
      }
    } catch (e) {
      console.log(e);
      handleClick();
      setSnackMessage(e.response.data.message);
      setSnackType("error");
      toggleDrawer(false,selectedOrderTab)();
    }
  };

  useEffect(() => {
    switch (active?.Type) {
      case "crypto":
        setLeverage(10);
        break;
      case "shares":
        setLeverage(100);
        break;
      case "shareIndex":
        setLeverage(100);
        break;
      case "currency":
        setLeverage(200);
        break;
      case "metal":
        setLeverage(100);
        break;
      case "oil_gas":
        setLeverage(100);
        break;
      default:
        setLeverage(1);
        break;
    }
  }, [active]);

  const leverages = [
    {
      value: 1,
      label: "x1",
    },
    {
      value: 5,
      label: "x5",
    },
    {
      value: 10,
      label: "x10",
    },
    {
      value: 20,
      label: "x20",
    },
    {
      value: 50,
      label: "x50",
    },
    {
      value: 100,
      label: "x100",
    },
    {
      value: 200,
      label: "x200",
    },
    {
      value: 500,
      label: "x500",
    },
  ];

  const CustomOption = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={
          props.isSelected
            ? styles.custom_option_selected
            : styles.custom_option
        }
      >
        <div
          className={styles.option}
          style={{
            color: props.data.color,
            backgroundColor: props.data.background,
            width: "auto",
          }}
        >
          {props.data.label}
        </div>
      </div>
    );
  };

  const DropdownIndicator = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
      <div className={styles.single_value_container}>
        <div className={styles.custom_icon}>
          <svg
            width={"1.7vh"}
            height={"1.6vh"}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5003 6L8.83366 10L4.16699 6"
              stroke="#A0A0AB"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {children}
      </div>
    </components.DropdownIndicator>
  );

  return (
    <Drawer
      open={open}
      anchor={window.innerWidth < 600 ? "bottom" : "right"}
      onClose={toggleDrawer(false,selectedOrderTab)}
      PaperProps={{
        className: styles.paper,
      }}
    >
      <div className={styles.account_drawer}>
        <div className={styles.top_drawer}>
          <div
            className={styles.drawer_header}
            style={{ justifyContent: "space-between" }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "1.6vh",
              }}
            >
              <img
                src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${active?.Subtitle.slice(
                  1
                )
                  .slice(0, -1)
                  .toLowerCase()}.svg`}
                alt="icon"
                onError={handleImgError}
              />
              {active?.Subtitle.slice(1).slice(0, -1)}
            </span>
            <div className={styles.close_icon} onClick={toggleDrawer(false,selectedOrderTab)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M12 4.00003L4 12M3.99997 4L11.9999 12"
                  stroke="white"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div
              className={styles.close_icon_mobile}
              onClick={toggleDrawer(false,selectedOrderTab)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M12 4.00003L4 12M3.99997 4L11.9999 12"
                  stroke="#51525C"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
          <div className={styles.drawer_body}>
            <div className={styles.account_tabs}>
              <button
                ref={buyOrder}
                className={`${styles.tab} ${
                  selectedOrderTab === "buy" ? styles.active_buy : ""
                }`}
                onClick={() => handleTabOrderChange("buy")}
              >
                <div className={styles.top}>
                  <span>{t("Buy")}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.99992 14.6665C11.6818 14.6665 14.6666 11.6817 14.6666 7.99984C14.6666 4.31794 11.6818 1.33317 7.99992 1.33317C4.31802 1.33317 1.33325 4.31794 1.33325 7.99984C1.33325 11.6817 4.31802 14.6665 7.99992 14.6665ZM6.49992 5.99984C6.49992 5.72369 6.72378 5.49984 6.99992 5.49984H9.99992C10.2761 5.49984 10.4999 5.72369 10.4999 5.99984V8.99984C10.4999 9.27598 10.2761 9.49984 9.99992 9.49984C9.72378 9.49984 9.49992 9.27598 9.49992 8.99984V7.20694L6.35347 10.3534C6.15821 10.5487 5.84163 10.5487 5.64637 10.3534C5.4511 10.1581 5.4511 9.84155 5.64637 9.64628L8.79281 6.49984H6.99992C6.72378 6.49984 6.49992 6.27598 6.49992 5.99984Z"
                      fill="#fcfcfc"
                    />
                  </svg>
                </div>
                <p>{(activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)).toFixed(4)}</p>
              </button>
              <button
                ref={sellOrder}
                className={`${styles.tab} ${
                  selectedOrderTab === "sell" ? styles.active_sell : ""
                }`}
                onClick={() => handleTabOrderChange("sell")}
              >
                <div className={styles.top}>
                  <span>{t("Sell")}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.99992 1.3335C11.6818 1.3335 14.6666 4.31826 14.6666 8.00016C14.6666 11.6821 11.6818 14.6668 7.99992 14.6668C4.31802 14.6668 1.33325 11.6821 1.33325 8.00016C1.33325 4.31826 4.31802 1.3335 7.99992 1.3335ZM6.49992 10.0002C6.49992 10.2763 6.72378 10.5002 6.99992 10.5002H9.99992C10.2761 10.5002 10.4999 10.2763 10.4999 10.0002V7.00016C10.4999 6.72402 10.2761 6.50016 9.99992 6.50016C9.72378 6.50016 9.49992 6.72402 9.49992 7.00016V8.79306L6.35347 5.64661C6.15821 5.45135 5.84163 5.45135 5.64637 5.64661C5.4511 5.84187 5.4511 6.15845 5.64637 6.35372L8.79281 9.50016H6.99992C6.72378 9.50016 6.49992 9.72402 6.49992 10.0002Z"
                      fill="#fcfcfc"
                    />
                  </svg>
                </div>
                <p>{(activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)).toFixed(4)}</p>
              </button>
            </div>
            <div className={styles.bottom_tabs}>
              <button
                className={`${styles.tab} ${
                  chosenBottomButton === "Market" ? styles.active : ""
                }`}
                onClick={() => setChosenBottomButton("Market")}
              >
                {t("Market")}
              </button>
              <button
                className={`${styles.tab} ${
                  chosenBottomButton === "Limit" ? styles.active : ""
                }`}
                onClick={() => setChosenBottomButton("Limit")}
              >
                {t("Limit")}
              </button>
              <button
                className={`${styles.tab} ${
                  chosenBottomButton === "Stop" ? styles.active : ""
                }`}
                onClick={() => setChosenBottomButton("Stop")}
              >
                {t("Stop")}
              </button>
            </div>
            {chosenBottomButton !== "Market" && (
              <div className={styles.price}>
                <div className={styles.item}>
                  <label htmlFor="price">{t("Price")}</label>
                  <input
                    type="number"
                    id="price"
                    value={EntryPrice}
                    style={limitError || stopError?{border:"1px solid var(--other-red)"}:{}}
                    onChange={(e) => handleChangeLimitStop('price' , e.target.value)}
                  />
                </div>
                <div className={styles.item}>
                  <label htmlFor="ticks">{t("Ticks")}</label>
                  <input 
                  type="number" 
                  id="ticks" 
                  value={ticks}                       
                  style={limitError || stopError?{border:"1px solid var(--other-red)"}:{}}
                  onChange={e=> handleChangeLimitStop('ticks',e.target.value)}/>
                </div>
              </div>
            )}

            <div className={styles.units_item}>
              <label htmlFor="units">{t("Volume")}</label>
              <input
                type="number"
                id="units"
                value={amount}
                style={amountError?{border:"1px solid var(--other-red)"}:{}}
                onChange={(e) => {
                  setAmount(e.target.value);
                  setAmountError(false);
                }}
              />
            </div>
            <div className={styles.margin}>
              <div className={styles.item}>
                <label htmlFor="margin">{t("Margin")}</label>
                <input type="text" id="margin" disabled value={`${account?.AccountCurrency == "EUR" ? "€" : "$"} ${chosenBottomButton !=='Market'
                  ? (
                    parseFloat(
                      amount *
                      active?.LotSize *
                      (account?.AccountCurrency == "EUR"
                        ? EntryPrice
                        : EntryPrice)
                    ) / leverage
                  ).toFixed(2)
                  : (
                    parseFloat(
                      amount *
                      active?.LotSize *
                      (account?.AccountCurrency == "EUR"
                        ? active?.Price * eurToUsd
                        : active?.Price)
                    ) / leverage
                  ).toFixed(2)
                  }`} />
              </div>
              <div className={styles.item}>
                <label htmlFor="leverage">{t("Leverage")}</label>
                <input
      type="text"
      placeholder={t("Currency")}
      value={leverages.find((el) => el.value === leverage)?.label || ""}
      list="leverages-list"
      disabled={true}
      className="custom_select"
      onChange={(e) => {
        const selectedOption = leverages.find(
          (el) => el.label === e.target.value
        );
        if (selectedOption) {
          setLeverage(selectedOption.value);
        }
      }}
    />
    <datalist id="leverages-list">
      {leverages.map((option) => (
        <option
          key={option.value}
          value={option.label}
        />
      ))}
    </datalist>
              </div>
            </div>

            <div className={styles.profit_and_loss}>
              <div className={styles.first_column}>
                <div>
                  <span>{t("Take Profit")}</span>
                  <label className={styles.switch}>
                    <input
                      type="checkbox"
                      checked={buttonTakeProfit}
                      onChange={handleCheckboxChange1}
                    />
                    <span class={styles.slider}></span>
                  </label>
                </div>
                {buttonTakeProfit && (
                  <>
                    <input
                      type="number"
                      value={valuesProfit.ticks}
                      style={takeProfitError?{border:"1px solid var(--other-red)"}:{}}
                      onChange={(e) =>
                        handleChangeValuesProfit("ticks", e.target.value)
                      }
                    />
                    <input
                      type="number"
                      value={valuesProfit.price}
                      style={takeProfitError?{border:"1px solid var(--other-red)"}:{}}
                      onChange={(e) => handleChangeValuesProfit('price',e.target.value)}
                    />
                    <input
                      type="number"
                      value={valuesProfit.money}
                      style={takeProfitError?{border:"1px solid var(--other-red)"}:{}}
                      onChange={(e) =>
                        handleChangeValuesProfit("money", e.target.value)
                      }
                    />
                    <input
                      type="number"
                      value={valuesProfit.percents}
                      style={takeProfitError?{border:"1px solid var(--other-red)"}:{}}
                      onChange={(e) =>
                        handleChangeValuesProfit("percents", e.target.value)
                      }
                    />
                  </>
                )}
              </div>
              <div className={styles.second_column}>
                {buttonTakeProfit | buttonStopLoss ? (
                  <>
                    <span>{t("Ticks")}</span>
                    <span>{t("Price")}</span>
                    <span>{t("Profit")}</span>
                    <span>%</span>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className={styles.third_column}>
                <div>
                  <span>{t("Stop Loss")}</span>
                  <label className={styles.switch}>
                    <input
                      type="checkbox"
                      checked={buttonStopLoss}
                      onChange={handleCheckboxChange2}
                    />
                    <span class={styles.slider}></span>
                  </label>
                </div>
                {buttonStopLoss && (
                  <>
                    <input
                      type="number"
                      value={valuesLoss.ticks}
                      style={stopLossError?{border:"1px solid var(--other-red)"}:{}}
                      onChange={(e) =>
                        handleChangeValuesLoss("ticks", e.target.value)
                      }
                    />
                    <input
                      type="number"
                      value={valuesLoss.price}
                      style={stopLossError?{border:"1px solid var(--other-red)"}:{}}
                      onChange={(e) => handleChangeValuesLoss('price', e.target.value)}
                    />
                    <input
                      type="number"
                      value={valuesLoss.money}
                      style={stopLossError?{border:"1px solid var(--other-red)"}:{}}
                      onChange={(e) =>
                        handleChangeValuesLoss("money", e.target.value)
                      }
                    />
                    <input
                      type="number"
                      value={valuesLoss.percents}
                      style={stopLossError?{border:"1px solid var(--other-red)"}:{}}
                      onChange={(e) =>
                        handleChangeValuesLoss("percents", e.target.value)
                      }
                    />
                  </>
                )}
              </div>
            </div>

            {chosenBottomButton === "Limit" && (
              <div className={styles.select_block}>
                <span>{t("Time in force")}</span>
                <select value={selectedOption} onChange={handleSelectChange}>
                  <option value="day">{t("DAY")}</option>
                  <option value="gtc">{t("GTC")}</option>
                </select>
              </div>
            )}
            {chosenBottomButton === "Stop" && (
              <div className={styles.select_block}>
                <span>{t("Time in force")}</span>
                <select value={selectedOption} onChange={handleSelectChange}>
                  <option value="day">{t("DAY")}</option>
                  <option value="gtc">{t("GTC")}</option>
                </select>
              </div>
            )}
          </div>
        </div>
        <div className={styles.drawer_footer}>
          <button
            style={{
              gap: "1.2vh",
              background:
                selectedOrderTab === "buy"
                  ? "var(--other-green)"
                  : "var(--other-red, #F97316)",
            }}
            onClick={() => OpenPosition()}
          >
            {selectedOrderTab === "buy" ? t("Buy") : t("Sell")}
            {` ${active?.Subtitle}`}
            {` ${chosenBottomButton !== 'Market'? EntryPrice :(activePrice * (account?.AccountCurrency === 'EUR'? eurToUsd: 1)).toFixed(4)}`}
            {selectedOrderTab === "buy" ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99992 14.667C11.6818 14.667 14.6666 11.6822 14.6666 8.00033C14.6666 4.31843 11.6818 1.33366 7.99992 1.33366C4.31802 1.33366 1.33325 4.31843 1.33325 8.00033C1.33325 11.6822 4.31802 14.667 7.99992 14.667ZM6.49992 6.00033C6.49992 5.72418 6.72378 5.50033 6.99992 5.50033H9.99992C10.2761 5.50033 10.4999 5.72418 10.4999 6.00033V9.00033C10.4999 9.27647 10.2761 9.50033 9.99992 9.50033C9.72378 9.50033 9.49992 9.27647 9.49992 9.00033V7.20743L6.35347 10.3539C6.15821 10.5491 5.84163 10.5491 5.64637 10.3539C5.4511 10.1586 5.4511 9.84203 5.64637 9.64677L8.79281 6.50033H6.99992C6.72378 6.50033 6.49992 6.27647 6.49992 6.00033Z" fill="#FCFCFC"/>
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301ZM6.49992 9.99967C6.49992 10.2758 6.72378 10.4997 6.99992 10.4997H9.99992C10.2761 10.4997 10.4999 10.2758 10.4999 9.99967V6.99967C10.4999 6.72353 10.2761 6.49967 9.99992 6.49967C9.72378 6.49967 9.49992 6.72353 9.49992 6.99967V8.79257L6.35347 5.64612C6.15821 5.45086 5.84163 5.45086 5.64637 5.64612C5.4511 5.84138 5.4511 6.15797 5.64637 6.35323L8.79281 9.49967H6.99992C6.72378 9.49967 6.49992 9.72353 6.49992 9.99967Z" fill="#FCFCFC"/>
                </svg>
            )}
          </button>
        </div>
      </div>
    </Drawer>
  );
}
